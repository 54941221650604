#root .Footer {
  padding: 5em 0em;
  text-align: center;
  &-quote {
    font-size: 2em;
    font-style: italic;
  }
  &-author {
    font-size: 2em;
    opacity: 0.5;
  }
}