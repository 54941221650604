#root .TimeLineItem {
  margin: 3em 0;
  position: relative;
  &-anchor {
    position: absolute;
    top: 30px;
    left: -37px;
    color: #24923e;
  }
  &-anchor:after {
    content: '';
    position: absolute;
    top: 4px;
    left: 11px;
    height: 2px;
    width: 20px;
    background-color: #24923e;
  }
  &-title {
    font-size: 1.5em;
    margin: 0;
  }
  &-primaryTitle {
    color: #24923e;
  }
  &-secondaryTitle {
    opacity: 0.5;
  }
  &-separator {
    line-height: 1;
    font-size: 0.2em;
    margin: 0 2em;
    vertical-align: middle;
  }
  &-bullets {
    margin: 0.5em 0 0.5em 1.25em;
  }
  &-skill {
    margin-bottom: 3px;
  }
}

#root .TimeLineDate {
  font-weight: bold;
  margin: 0;
  &-months {
    opacity: 0.5;
  }
}