#root .PortfolioItem {
  padding: 3em 0;
  &-cardLink {
    margin: 0 0 2em 0;
    @media (min-width: 768px) {
      margin: 0 2em 0 0;
    }
    &:hover {
      border-radius: 4px;
      box-shadow: 0 0 0 1px #d4d4d5, 0 2px 4px 0 rgba(34,36,38,.12), 0 2px 10px 0 rgba(34,36,38,.15);
    }
  }
  &-card {
    width: 100%;
    max-width: 450px;
    @media (min-width: 768px) {
      height: 252px;
      width: 452px;
    }
  }
  &-image {
    @media (min-width: 768px) {
      position: absolute;
    }
  }
  &-placeholder {
    display: none;
    @media (min-width: 768px) {
      display: block;
      max-width: none;
      height: 100%;
    }
  }
}