#root .Heading {
  &-title {
    font-size: 2em;
    font-weight: normal;
    margin: 1.5em 0 0 0;
    @media (min-width: 768px) {
      font-size: 4em;
      margin-top: 3em;
    }
  }
  &-subtitle {
    font-size: 1.5em;
    font-weight: normal;
    margin: 0.5em 0 0.75em 0;
    @media (min-width: 768px) {
      font-size: 1.7em;
      margin-bottom: 1em;
    }
  }
  &-links {
    a {
      color: white;
    }
    a:hover {
      opacity: 0.8;
    }
  }
}