#root .TimeLine {
  margin: 2em 0;
  @media (min-width: 768px) {
    margin: 4em 0;
  }
  &-header {
    font-size: 2em;
  }
  &-items {
    margin: 0 0 0 10px;
    padding: 0 0 0 30px;
    border-left: 2px dashed #5d5d5d73;
  }
  &-items > :first-child {
    margin-top: 2em;
  }
}